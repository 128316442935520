import {useEventsQuery} from '~/graphql/graphql';

export default function () {
  const query = useState('query', () => '');

  const queryTrimmed = computed(() => query.value.trim());

  const {result: eventResults} = useEventsQuery(() => ({
    query: queryTrimmed.value,
  }), () => ({
    debounce: 400,
    enabled: query.value.length > 2,
  }));

  const result = computed(() => eventResults.value?.events?.data ?? []);

  return {
    query,
    result,
  };
}
